export const SERVER_BASE_URL = 'https://uilss-backend.onrender.com';
// export const SERVER_BASE_URL = 'http://localhost:5000';
export const ACCESS_TOKEN_KEY = 'access-token';
export const REFRESH_TOKEN_KEY = 'refresh-token';
export const ROLE_KEY = 'user-role';
export const QUERY_CACHE_TIME = 1000 * 60 * 5; // 5 minutes
export const REMEMBER_ME_MATRIC_KEY = 'remember-me-matric';
export const REMEMBER_ME_KEY = 'remember-me';
export const SILENT_QUERY_OPTIONS = {
	// override to fail silently
	throwOnError() {
		return false;
	},
};
export const CACHED_QUERY_OPTIONS = {
	staleTime: QUERY_CACHE_TIME,
	...SILENT_QUERY_OPTIONS,
};
