import { lazy } from 'react';
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import RouteAuth from '../components/RouteAuth';
import RootLayout from '../components/layout/RootLayout';
const Home = lazy(() => import('../pages/home'));
const Event = lazy(() => import('../pages/events'));
const Engage = lazy(() => import('../pages/engage'));
const About = lazy(() => import('../pages/about'));
const Gallery = lazy(() => import('../pages/gallery'));
const Blogs = lazy(() => import('../pages/blog'));
const BlogInner = lazy(() => import('../pages/blog/[slug]'));
const EventInner = lazy(() => import('../pages/events/[id]'));
const Mentorship = lazy(() => import('../pages/mentor'));
const Volunteer = lazy(() => import('../pages/volunteer'));
const Suggestion = lazy(() => import('../pages/suggestion'));
const Fundraising = lazy(() => import('../pages/fund'));
const ELibrary = lazy(() => import('../pages/e-library'));
const NotFoundView = lazy(() => import('../pages/not-found'));
const Login = lazy(() => import('../pages/auth/login'));
const Signup = lazy(() => import('../pages/auth/signup'));
//Admin Pages
const AdminDashboardLayout = lazy(() =>
	import('../components/layout/AdminDashboardLayout')
);
const ManageEvent = lazy(() => import('../pages/admin/events/Events'));
const Materials = lazy(() => import('../pages/admin/materials/Materials'));
const BlogPosts = lazy(() => import('../pages/admin/blogs/Blogs'));
const AdminNewBlog = lazy(() => import('../pages/admin/blogs/NewBlog'));
const BlogPostInner = lazy(() => import('../pages/admin/blogs/[id]'));
const AdminDashboard = lazy(() => import('../pages/admin/dashboard/Dashboard'));
const AdminLogin = lazy(() => import('../pages/admin/auth/login'));

const ROUTES = [
	{
		path: '/',
		component: Home,
		title: 'Home',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/blogs',
		component: Blogs,
		title: 'Blogs',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/blogs/:slug',
		component: BlogInner,
		title: 'Blog Inner',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/events',
		component: Event,
		title: 'Event',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/events/:id',
		component: EventInner,
		title: 'Event Inner',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/engage',
		component: Engage,
		title: 'Engage',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/about',
		component: About,
		title: 'About',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/gallery',
		component: Gallery,
		title: 'Gallery',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/mentor',
		component: Mentorship,
		title: 'Mentorship',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/volunteer',
		component: Volunteer,
		title: 'Volunteer',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/suggestion',
		component: Suggestion,
		title: 'Suggestion',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/fundraising',
		component: Fundraising,
		title: 'Fundraising',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/e-library',
		component: ELibrary,
		title: 'ELibrary',
		isAuth: false,
		isPublic: true,
	},
	{
		path: '/auth/login',
		component: Login,
		title: 'Login',
		isAuth: true,
		isPublic: true,
	},
	{
		path: '/auth/signup',
		component: Signup,
		title: 'Signup',
		isAuth: true,
		isPublic: true,
	},

	//  Routing for the admins
	{
		path: '/admin',
		component: AdminLogin,
		title: 'Admin Login',
		isAuth: true,
		isPublic: true,
		isAdmin: true,
	},
	{
		path: '/admin',
		component: AdminDashboardLayout,
		skipRouteAuth: true,
		subPaths: [
			{
				path: '/admin/dashboard',
				component: AdminDashboard,
				title: 'Admin Dashboard',
				isAuth: false,
				isPublic: false,
				isAdmin: true,
			},
			{
				path: '/admin/blogs',
				component: BlogPosts,
				title: 'Blog Posts',
				isAuth: false,
				isPublic: false,
				isAdmin: true,
			},
			{
				path: '/admin/blogs/new',
				component: AdminNewBlog,
				title: 'New Blog Post',
				isAuth: false,
				isPublic: false,
				isAdmin: true,
			},
			{
				path: '/admin/blogs/:id',
				component: BlogPostInner,
				title: 'Blog Post Inner',
				isAuth: false,
				isPublic: false,
				isAdmin: true,
			},
			{
				path: '/admin/events',
				component: ManageEvent,
				title: 'Events',
				isAuth: false,
				isPublic: false,
				isAdmin: true,
			},
			{
				path: '/admin/materials',
				component: Materials,
				title: 'Materials',
				isAuth: false,
				isPublic: false,
				isAdmin: true,
			},
		],
	},
];

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path='/' element={<RootLayout />}>
			{ROUTES.map((route) => {
				return (
					<Route
						key={route.path}
						path={route.path}
						element={<RouteAuth {...route} />}
					>
						{route.subPaths &&
							route.subPaths.length > 0 &&
							route.subPaths.map((subPath) => (
								<Route
									key={subPath.path}
									path={subPath.path}
									element={<RouteAuth {...subPath} />}
								/>
							))}
					</Route>
				);
			})}
			<Route
				path='*'
				element={
					<RouteAuth {...{ component: NotFoundView, skipRouteAuth: true }} />
				}
			/>
		</Route>
	)
);

const Router = () => {
	return <RouterProvider router={router} />;
};

export default Router;
