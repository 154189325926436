import React, { lazy } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../footer/Footer';
const ScrollToTop = lazy(() => import('../ScrollToTop'));
const NavBar = lazy(() => import('../navbar/NavBar'));

const RootLayout = () => {
	const { pathname } = useLocation();
	return (
		<>
			<ScrollToTop />
			<NavBar />
			<div className='min-h-[calc(100vh-88px)] lg:min-h-[calc(100vh-96px)]'>
				<Outlet />
			</div>
			{!pathname.includes('/admin') && <Footer />}
		</>
	);
};

export default RootLayout;
