import React, { Suspense, useEffect, useState } from 'react';
import { ACCESS_TOKEN_KEY, ROLE_KEY } from '../lib/constant';
import { useNavigate } from 'react-router-dom';
import Spinner from './Spinner';
import useDocumentTitle from '../hooks/useDocumentTitle';

const RouteAuth = ({
	component: Component,
	title,
	isAuth,
	isPublic,
	isAdmin,
	skipRouteAuth,
}) => {
	const [loaded, setLoaded] = useState(false);
	const navigate = useNavigate();
	const [setTitle] = useDocumentTitle();
	setTitle(title);

	useEffect(() => {
		if (skipRouteAuth) {
			return setLoaded(true);
		}

		if (typeof window === 'undefined') return;

		if (isAuth && isPublic && localStorage.getItem(ACCESS_TOKEN_KEY)) {
			navigate('/');
		}

		if (!isPublic && !localStorage.getItem(ACCESS_TOKEN_KEY)) {
			navigate('/auth/login');
		}

		if (
			isAdmin &&
			!isPublic &&
			(!localStorage.getItem(ACCESS_TOKEN_KEY) ||
				localStorage.getItem(ROLE_KEY) !== 'ADMIN')
		) {
			navigate('/');
		}

		setLoaded(true);
	}, [isAdmin, isAuth, skipRouteAuth, isPublic, navigate]);

	if (!loaded) return null;

	const SuspenseLoader = () => (
		<div className='w-full flex justify-center pt-4'>
			<Spinner />
		</div>
	);

	return (
		<Suspense fallback={<SuspenseLoader />}>
			<Component />
		</Suspense>
	);
};

export default RouteAuth;
