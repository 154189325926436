import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	open: true,
};

const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		toggleSidebar(state, action) {
			const { open } = action.payload;
			state.open = open;
		},
	},
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
