import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Router from './router/Router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './lib/redux/store';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			throwOnError(error) {
				handleOnRequestError(error);
				return false;
			},
		},
		mutations: {
			onError: handleOnRequestError,
			onSuccess: handleOnRequestSuccess,
		},
	},
});

function handleOnRequestError(error) {
	let errorMessage =
		error.response?.data.error || error.message || 'Request failed';
	toast.error(errorMessage);
}

function handleOnRequestSuccess(data) {
	toast.success(data.message || 'Success');
}

const App = () => {
	return (
		<ReduxProvider store={store}>
			<QueryClientProvider client={queryClient}>
				<Router />
				<ToastContainer />
			</QueryClientProvider>
		</ReduxProvider>
	);
};

export default App;
