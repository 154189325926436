const useDocumentTitle = () => {
	const setTitle = (title) => {
		let documentTitle = "Law Students' Society";
		if (title) {
			documentTitle += ` - ${title}`;
		}
		document.title = documentTitle;
	};

	return [setTitle];
};

export default useDocumentTitle;
